<template>
  <div class="d-flex align-center">
    <!-- Cards: Demander(His) and ProductSupplier(My) -->
    <DemanderCard
      :demander="contactRequestInbound.contactRequest.demander"
      :width="width"
    />
    <ProductSupplierCard
      :product-supplier="contactRequestInbound.contactRequest.productSupplier"
      :width="width"
    />

    <!-- Subject + Labels -->
    <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
    <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
    <!-- <div class="d-flex flex-column text-truncate">
        <span
          class="text-truncate me-4 ms-sm-2 ms-0"
          :class="contactRequestInbound.isRead ? '' : 'font-weight-bold'"
          :style="contactRequestInbound.isRead ? '' : 'color: black'"
        >
          {{ contactRequestInbound.contactRequest.subject }}
        </span>
        <div class="d-flex flex-row text-truncate">
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-badge
              v-for="label in contactRequestInbound.labels"
              :key="label"
              :color="resolveContactRequestInboundLabelColor(label)"
              :dot="contactRequestInbound.labels.length > 1"
              inline
              class="align-self-center label-dot"
              :class="!(contactRequestInbound.labels.length > 1) ? `${resolveContactRequestInboundLabelColor(label)}--text v-badge-light-bg` : ''"
              :content="label"
            ></v-badge>
          </template>
        </div>
      </div> -->
  </div>
</template>

<script>

// Components
import DemanderCard from '@/components/Cards/DemanderCard.vue'
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import useContactRequestInboundHandler from '@/views/apps/contact-request/contact-request-inbound/useContactRequestInboundHandler'

export default {
  components: {
    DemanderCard,
    ProductSupplierCard,
  },
  props: {
    contactRequestInbound: {
      type: Object,
      default: () => {},
    },

    // Each component width (Si  queremos diferenciar hay que crear una propiedad por cada componente)
    width: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { resolveContactRequestInboundLabelColor } = useContactRequestInboundHandler

    return {
      resolveContactRequestInboundLabelColor,
    }
  },
}
</script>
