<template>
  <!-- TODO - Por ahora primary, cuando tengamos un indicador de bloqueo lo cambiamos -->
  <v-tooltip
    top
    :color="contactRequestInbound.id ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <!-- :to="menuItemContactRequestInbound.route" -->
      <v-icon
        class="mx-2"
        :color="contactRequestInbound.id ? 'primary' : 'grey lighten-1'"
        :disabled="(!isNavegable || !contactRequestInbound.id)"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemContactRequestInbound.route, contactRequestInbound)"
      >
        {{ menuItemContactRequestInbound.icon }}
      </v-icon>
    </template>
    <span>{{ menuItemContactRequestInbound.title }}</span>
  </v-tooltip>
</template>

<script>
import { mdiEye } from '@mdi/js'

// Router
// import useRouterPath from '@/router/useRouterPath'
import { useRouter } from '@core/utils'
import { resolveContactRequestInboundRoute } from '@/services/master/ContactRequestInboundStatus'

export default {
  props: {
    contactRequestInbound: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { router } = useRouter()

    // const { menuItemContactRequestInbound } = useRouterPath()
    const menuItemContactRequestInbound = Object.assign(
      resolveContactRequestInboundRoute(props.contactRequestInbound.status),
      {
        icon: mdiEye,
        title: 'Go to ContactRequestInbound',
      },
    )

    // Go to ChatMessage by contactRequestInbound: Añadimos el query a la ruta
    const goTo = (toRoute, contactRequestInbound) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }
      const newRoute = { ...toRoute, query: { contactRequestInboundId: contactRequestInbound.id } }
      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemContactRequestInbound,
      goTo,
    }
  },
}
</script>
