<template>
  <UserCard
    :user="demander.user"
    :company="demander.company"
    :width="width"
  ></UserCard>
</template>
<script>

import UserCard from '@/components/Cards/UserCard.vue'

export default {
  components: {
    UserCard,
  },
  props: {
    demander: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: null, // null or undefined
    },
  },
}
</script>
