<template>
  <!-- TODO - Por ahora primary, cuando tengamos un indicador de bloqueo lo cambiamos -->
  <v-tooltip
    top
    :color="campaign.id ? 'primary' : 'grey lighten-1'"
  >
    <template v-slot:activator="{ on, attrs }">
      <!-- Desactivamos cuando No sea navegable y el indicador === false -->
      <!-- :to="menuItemCampaign.route" -->
      <v-icon
        class="mx-2"
        :color="campaign.id ? 'primary' : 'grey lighten-1'"
        :disabled="(!isNavegable || !campaign.id)"
        v-bind="attrs"
        v-on="on"
        @click.stop="goTo(menuItemCampaign.route, campaign)"
      >
        {{ menuItemCampaign.icon }}
      </v-icon>
    </template>
    <span>Go to {{ menuItemCampaign.title }} {{ campaign.name }}</span>
  </v-tooltip>
</template>

<script>
import { mdiEye } from '@mdi/js'

// Router
// import useRouterPath from '@/router/useRouterPath'
import { useRouter } from '@core/utils'
import { resolveCampaignRoute } from '@/services/master/CampaignStatus'

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    isNavegable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // Control de navegación
    const { router } = useRouter()

    // const { menuItemCampaign } = useRouterPath()
    const menuItemCampaign = Object.assign(
      resolveCampaignRoute(props.campaign.status), // Published default
      {
        icon: mdiEye,
        title: 'Campaign',
      },
    )

    // Go to ChatMessage by campaign: Añadimos el query a la ruta
    const goTo = (toRoute, campaign) => {
      // Exit if not navegable
      if (!props.isNavegable) {
        return
      }

      // const routeStatus = resolveCampaignRoute(campaign.status)
      // const newRoute = { ...routeStatus.route, query: { campaignId: campaign.id } }
      const newRoute = { ...toRoute, query: { campaignId: campaign.id } }
      router.replace(newRoute)
    }

    return {
      // Router navigation
      menuItemCampaign,
      goTo,
    }
  },
}
</script>
