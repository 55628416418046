<template>
  <div>
    <div class="d-flex align-center">
      <!-- Cards: My Product: ProductSupplier -->
      <ProductSupplierCard :product-supplier="campaign.productSupplier" />

      <div class="d-flex flex-column text-truncate">
        <span class="text-truncate me-4 ms-sm-2 ms-0">
          {{ campaign.subject }}
        </span>
        <div class="d-flex flex-row text-truncate">
          <!-- <template v-if="$vuetify.breakpoint.smAndUp"> -->
          <template>
            <v-badge
              color="secondary"
              inline
              :content="campaign.type"
            ></v-badge>
            <v-badge
              v-if="campaign.type === 'internal'"
              color="secondary"
              inline
              :content="campaign.contactIds.length"
            ></v-badge>
            <v-badge
              v-if="campaign.type === 'blind_scan'"
              color="secondary"
              inline
              :content="campaign.country"
            ></v-badge>

            <!-- :class="`${resolveCampaignLabelColor(label)}--text`" -->
            <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
            <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
            <v-badge
              v-for="label in campaign.labels"
              :key="label"
              :color="resolveCampaignLabelColor(label)"
              :dot="campaign.labels.length > 1"
              inline
              class="align-self-center label-dot"
              :class="!(campaign.labels.length > 1) ? `${resolveCampaignLabelColor(label)}--text v-badge-light-bg` : ''"
              :content="label"
            ></v-badge>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiMapMarkerOutline } from '@mdi/js'

import ProductSupplierCard from './ProductSupplierCard.vue'

export default {
  components: {
    ProductSupplierCard,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      icons: {
        mdiMapMarkerOutline,
      },
    }
  },
}
</script>
