import {
  mdiInboxOutline,
} from '@mdi/js'

// Notification
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { resolveContactRequestInboundStatus, CONTACT_REQUEST_INBOUND_STATUS } from '@/services/master/ContactRequestInboundStatus'
import useRouterPath from '@/router/useRouterPath'

export default function useContactRequestInboundHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendContactRequestInboundNotification = (title, variant = TYPE.ERROR, icon = mdiInboxOutline) => {
    // toast.warning(`Product Supplier ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `ContactRequestInbound - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }

  // Not in use
  // const moveToFolderMenuListItems = computed(() => currentRoute => {
  //   const items = []

  //   const currentFolder = currentRoute.params.folder

  //   if (currentRoute.name !== 'apps-contact-request-inbound') {
  //     items.push({ title: 'Inbox', value: 'inbox', icon: mdiInboxOutline })
  //   }
  //   if (currentFolder !== 'draft') items.push({ title: 'Draft', value: 'draft', icon: mdiSquareEditOutline })
  //   if (currentFolder !== 'spam') items.push({ title: 'Spam', value: 'spam', icon: mdiAlertOctagonOutline })
  //   if (currentFolder !== 'trash') items.push({ title: 'Trash', value: 'trash', icon: mdiTrashCanOutline })

  //   return items
  // })

  // LABELS
  const updateLabelMenuListItems = [
    { title: 'Personal', value: 'personal', color: 'success' },
    { title: 'Company', value: 'company', color: 'primary' },
    { title: 'Important', value: 'important', color: 'warning' },
    { title: 'Private', value: 'private', color: 'error' },
  ]

  const resolveContactRequestInboundLabelColor = label => {
    if (label === 'personal') return 'success'
    if (label === 'company') return 'primary'
    if (label === 'important') return 'warning'
    if (label === 'private') return 'error'

    return 'secondary'
  }

  const { menuItemContactRequestInboundReceived, menuItemContactRequestInboundAccepted, menuItemContactRequestInboundRejected } = useRouterPath()

  // CUSTOM ACTIONS
  const customActionReceived = {
    title: menuItemContactRequestInboundReceived.value.text,
    value: menuItemContactRequestInboundReceived.value.value,
    color: menuItemContactRequestInboundReceived.value.color,
    icon: menuItemContactRequestInboundReceived.value.icon,
    route: menuItemContactRequestInboundReceived.value.route,
  }
  const customActionAccepted = {
    title: menuItemContactRequestInboundAccepted.text,
    value: menuItemContactRequestInboundAccepted.value,
    color: menuItemContactRequestInboundAccepted.color,
    icon: menuItemContactRequestInboundAccepted.icon,
    route: menuItemContactRequestInboundAccepted.route,
  }
  const customActionRejected = {
    title: menuItemContactRequestInboundRejected.text,
    value: menuItemContactRequestInboundRejected.value,
    color: menuItemContactRequestInboundRejected.color,
    icon: menuItemContactRequestInboundRejected.icon,
    route: menuItemContactRequestInboundRejected.route,
  }

  return {
    sendContactRequestInboundNotification,

    // Custom Actions
    customActionReceived,
    customActionAccepted,
    customActionRejected,

    // Selected Mail Actions Menu List Item
    updateLabelMenuListItems,

    // UI ContactRequestInbound
    resolveContactRequestInboundLabelColor, // Only color
    resolveContactRequestInboundStatus, // object: color and icon
    CONTACT_REQUEST_INBOUND_STATUS,
  }
}
