<template>
  <!-- v-model="isDialogActive" -->
  <v-dialog
    :value="isDialogActive"
    width="500"
    scrollable
    @click:outside="$emit('update:is-dialog-active',false)"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ title }}
        <span
          v-if="dependenciesError"
          class="pl-1"
        >(with dependencies)</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <!-- Campaigns -->
        <div
          v-if="dependenciesError && dependenciesError.campaigns"
          class="d-flex flex-column"
        >
          <!-- Link -->
          <div class="d-flex align-center font-weight-medium text-xl primary--text mt-7">
            <v-list-item
              link
              :to="menuItemCampaign.route"
              class="pl-0"
            >
              <v-btn
                icon
                :outlined="false"
                color="primary"
              >
                <v-icon size="22">
                  {{ menuItemCampaign.icon }}
                </v-icon>
              </v-btn>
              <span class="">{{ menuItemCampaign.title }}</span>
            </v-list-item>
          </div>

          <!-- List of Campaigns -->
          <div
            v-for="(campaign, index) in dependenciesError.campaigns"
            :key="index"
            class="d-flex align-center"
          >
            <CampaignNavegableIcon
              :campaign="campaign"
              :is-navegable="true"
            ></CampaignNavegableIcon>
            <CampaignCard
              class="d-flex ms-3"
              :campaign="campaign"
            />
            <!-- <v-spacer></v-spacer>
            <CampaignNavegableIcon
              :campaign="campaign"
              :is-navegable="true"
            ></CampaignNavegableIcon> -->
          </div>
        </div>

        <!-- ContactRequestInbounds -->
        <div
          v-if="dependenciesError && dependenciesError.contactRequestInbounds"
          class="d-flex flex-column"
        >
          <!-- Link -->
          <div class="d-flex align-center font-weight-medium text-xl primary--text mt-7">
            <v-list-item
              link
              :to="menuItemContactRequestInbound.route"
              class="pl-0"
            >
              <v-btn
                icon
                :outlined="false"
                color="primary"
              >
                <v-icon size="22">
                  {{ menuItemContactRequestInbound.icon }}
                </v-icon>
              </v-btn>
              <span class="">{{ menuItemContactRequestInbound.title }}</span>
            </v-list-item>
          </div>

          <!-- List of ContactRequestInbounds -->
          <div
            v-for="(contactRequestInbound, index) in dependenciesError.contactRequestInbounds"
            :key="index"
            class="d-flex align-center"
          >
            <ContactRequestInboundNavegableIcon
              :contact-request-inbound="contactRequestInbound"
              :is-navegable="true"
            />
            <ContactRequestInboundCard
              :contact-request-inbound="contactRequestInbound"
              width="200px"
            />
            <!-- <v-spacer></v-spacer> -->
            <!-- <ContactRequestInboundNavegableIcon
              :contact-request-inbound="contactRequestInbound"
              :is-navegable="true"
            ></ContactRequestInboundNavegableIcon> -->
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="$emit('update:is-dialog-active',false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
          {{ buttonTextCancel }}
        </v-btn>

        <!-- @click="$emit('update:is-dialog-active',false); onDelete()" -->
        <!-- @click="$emit('update:is-dialog-active',false); $emit('delete',id)" -->
        <v-btn
          color="error"
          outlined
          text
          @click="$emit('update:is-dialog-active',false); $emit('delete',id, false)"
        >
          <v-icon>
            {{ icons.mdiDelete }}
          </v-icon>
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDelete, mdiClose } from '@mdi/js'
import useRouterPath from '@/router/useRouterPath'

import CampaignCard from '@/components/Cards/CampaignCard.vue'
import CampaignNavegableIcon from '@/components/NavegableIcon/CampaignNavegableIcon.vue'

import ContactRequestInboundCard from '@/components/Cards/ContactRequestInboundCard.vue'
import ContactRequestInboundNavegableIcon from '@/components/NavegableIcon/ContactRequestInboundNavegableIcon.vue'

export default {
  components: {
    CampaignCard,
    CampaignNavegableIcon,

    ContactRequestInboundCard,
    ContactRequestInboundNavegableIcon,
  },
  model: {
    prop: 'isDialogActive',
    event: 'update:is-dialog-active',
  },
  props: {
    isDialogActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },

    // Errores de dependencias recibidos del servidor
    //  campaigns: error.response.data.error.campaigns,
    //  contactRequests: error.response.data.error.contactRequests,
    dependenciesError: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: 'Delete',
    },
    text: {
      type: String,
      default: 'The user will be removed from the system permanently.',
    },
    buttonText: {
      type: String,
      default: 'Delete',
    },
    buttonTextCancel: {
      type: String,
      default: 'Cancel',
    },
  },
  setup(props, { emit }) {
    const { menuItemCampaign, menuItemContactRequestInbound } = useRouterPath()
    const onDelete = () => {
      emit('delete', props.id)
    }

    return {
      // Dependencies for ProductSupplier
      menuItemCampaign,
      menuItemContactRequestInbound,

      onDelete,
      icons: {
        mdiClose,
        mdiDelete,
      },
    }
  },
}
</script>
